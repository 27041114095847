export const en = {
  translation: {
    // header translate
    header: {
      tabs: {
        creditAndOther: {
          title: "Patients",
          subtitle: "Have the wise choices",
        },
        guidesAndTips: {
          title: "Clinics",
          subtitle: "Best to your patients",
        },
        calculators: {
          title: "Partners",
          subtitle: "Best way to PNPL",
        },
      },
      buttons: {
        login: "Log In",
        blog: "Blogzzz"
      },
      meuns: {
        blog: "Blog",
        selectLanguage: "en"
      },
    },
    pages: {
      // home page translate
      home: {
        title1: "Financing platform",
        title2: "for health procedures",
        title: {
          content:
            "We are giving patients ability to easily split their payments for medical procedures with the best offer from various banks",
          button: {
            label: "LEARN MORE",
            content1: "AS A CLINIC",
            content2: "AS A PATIENT",
            content3: "AS A PARTNER",
          }
        },
        everythingYouNeed: {
          item1: {
            title1: "Get a procedure now with ",
            title2: "the best possible financing",
            label: "FOR PATIENTS",
            content:
              "Finamba platform gives you ability to choose the cheapest, the fastest, and the most convenient financing option for you.",
            link: "LEARN MORE"
          },
          item2: {
            title1: "Help your patients with ",
            title2: "payment for procedures",
            label: "FOR CLINICS",
            content:
              "Give your customer financial freedom by splitting their payments or giving the product with 14 day grace, while you get paid in full. It’s a win situation",
            link: "LEARN MORE"
          },
          item3: {
            title1: "With one integration get ",
            title2: "into large network of clinics",
            label: "FOR PARTNERS",
            content:
              "Expand your digital footprint and start building additional portfolio with new revenue streams. You can instantly get to the sales financing market without any hustle",
            link: "LEARN MORE"
          },
        },
        footer: {
          title: "Get started with Finamba today",
          subtitle: "",
          buttonLabel: "START NOW"
        },
      },
      // patient page translate
      patient: {
        title1: "Get the procedure ",
        title2: "now and pay later",
        title3: "without overpaying",
        title: {
          content: "Finamba offer you an easy and convenient way to have the medical procedures you need now and pay over time",
          button: {
            label: "APPLY NOW",
          }
        },
        applysoon: "APPLY SOON",
        description: {
          title: "How it works?",
          content: "To get your procedure now and pay later is easy - you have to follow just few steps at any of our partnership clinics.",
          item1: {
            label: "STEP 1",
            title1: "Find the QR code ",
            title2: "or ask help",
            content: "At every our network clinic you can find an QR code which you just need to scan with your smartphone's camera. If you can't find one, ask help to the administrator.",
          },
          item2: {
            label: "STEP 2",
            title1: "Fill out the application",
            title2: "",
            content: "Fill out the online or paper application. Online application is fully done online with your mobile or any other device. For paper application ask help to the clinic's administrator.",
          },
          item3: {
            label: "STEP 3",
            title1: "Pick your ",
            title2: "payment plan ",
            content: "Decide in how many payments you want to pay the cost for medical procedure. It can be from 14 days till even 60 months. Note that each clinic has their own offering.",
          },
          item4: {
            label: "STEP 4",
            title1: "Pick the best financing ",
            title2: "option for you ",
            content: "You will get the best possible offers from our network banks and lenders. May it be from your core bank, the cheapest offer, the fastest offer, any other special offers - including 0 interest offers. Pick one which suits the best for you!",
          },
          item5: {
            label: "STEP 5",
            title1: "Sign the contract",
            title2: "",
            content: "The final step is to sign the agreement with your chosen bank or lender, and inform the clinic that the contract is sign using Finamba platform and all is set for you procedure. ",
          }
        },
        reason: {
          title: "Why choose Finamba?",
          item1: {
            title: "Personalized offers and best price",
            content: "Our platform is a marketplace which will find the best possible offers for you personally. We will sort out from various offers the best for you and present it with our comments.",
          },
          item2: {
            title: "Quick & Simple",
            content: "Applying is easy. All you need is to provide a few pieces of information and you start to get offers.",
          },
          item3: {
            title: "No credit card needed",
            content: "Always know how much you'll pay with easy equal payments. No surprises, and no credit card needed.",
          },
          item4: {
            title: "Finamba works on your side",
            content: "We now how financing can be vague and with different tricks from banks and lenders for higher profit. That's why we list the pros and cons of each offer. You will not get screwed ever again with vague and fishy terms. We got your back and work for you!",
          }
        },
        faq: {
          title: "Frequently Asked Questions",
          item1: {
            question: "Am I eligible to use Finamba?",
            answer: "Every person reached 18 years age can use Finamba services."
          },
          item2: {
            question: "Can I change the terms of agreements provided by banks or lenders?",
            answer: "You can change the specific details of agreement - term, loan amount, and payment date. Unfortunately, you can’t change the general terms of agreements or any other specifics."
          },
          item3: {
            question: "Is my information safe with Finamba?",
            answer: "Yes. Finamba uses 128-bit encryption, which is considered nearly impossible to crack, to protect its data transmission. It also vows not to sell your information to third parties."
          },
          item4: {
            question: "Where I can get Finamba services?",
            answer: "Finamba services you can use at every clinic, shop and service provider within Finamba network. You can use Finamba even outside our network, just fill out the application on www.finamba.com"
          },
          item5: {
            question: "Do I need to pay Finamba for services?",
            answer: "No, you will never need to pay for Finamba services. Finamba is an independent, financial literacy, and finance product comparison service. Finamba is compensated for each successful transaction by bank or loan provider within Finamba network."
          }
        },
        footer: {
          title: "Still have questions?",
          content: "We are here to help answer all of your questions, just contact us.",
          buttonLabel: "CONTACT US"
        }
      },
      // clinic page
      clinic: {
        title1: "Give your patients",
        title2: "the best way",
        title3: "to split the price",
        title: {
          content: "A modern and flexible financing platform for clinics to deliver the cheapest and most convenient “procedure now, pay later” option",
          button: {
            label: "GET STARTED FOR FREE",
          }
        },
        percentage: {
          item1: {
            title: "31%",
            content: "increase in conversations"
          },
          item2: {
            title: "50%",
            content: "time saving on applications"
          },
          item3: {
            title: "20%",
            content: "less admin costs"
          }
        },
        payment: {
          title: "Payment options loved by patients",
          content: "Give your customer financial freedom by splitting their payments or giving the product with 14-day grace, while you get paid in full. It's a win win situation.",
          button: {
            label: "GET STARTED FOR FREE"
          }
        },
        card: {
          title: "No risk. Just benefits",
          content: "Using Finamba is proven to boost your patients' health and make running your clinic easier.",
          item1: {
            title: "Flexible payments",
            content: "Customers are able to choose various financial products which suits them the best from banks/financiers."
          },
          item2: {
            title: "Effortless application",
            content: "With no added cost to the order and automatic payments, filling out the application is just as easy as making any regular card purchase. More freedom means happy and healthy patients."
          },
          item3: {
            title: "Cheaper than PSP",
            content: "By giving your customer the ability to use finance products you escape the commission needed to pay for transaction of card/banklink/etc. to PSP services. This saves you 2-3%."
          },
          item4: {
            title: "Easy implementation",
            content: "Finamba platform is easy to install and you will never need another integration with bank or financier. You can easy choose with whom you want to work and with whom not."
          },
          item5: {
            title: "Get new customers",
            content: "Finamba refers thousands of patients to our partners via integrated marketing campaigns and our tools."
          },
          item6: {
            title: "Problem? No problem!",
            content: "We’re here to help if anything unexpected happens to you or your customers during the treatment journey."
          },
          item7: {
            title: "Zero fraud liability",
            content: "We make it easy for you to track your patient agreements, report returns, and everything in between."
          },
          item8: {
            title: "Upfront payments",
            content: "You always get paid fast and in full with any Finamba payment method."
          },
          item9: {
            title: "Seller protection",
            content: "We assume all credit & fraud risk when our payment methods are used, so you can just focus on your clinic."
          },
          item10: {
            title: "Global solutions",
            content: "All of our products are made to be adaptable to fit local preference and legislation."
          },
          item11: {
            title: "A lot of financial products",
            content: "You’ll always have the latest and greatest financial products from our financial partners."
          },
          item12: {
            title: "Settlements made simple",
            content: "Customize settlement reports to best suit your accounting system."
          }
        },
        focus: {
          title: "We focus on your care towards patient",
          text1: {
            title: "22%",
            content: "patients say they have steered clear of some sort of medical care because of the expense"
          },
          text2: {
            title: "15%",
            content: "report that another family member in their household decided to forgo medical care because it was too expensive"
          },
          text3: {
            title: "30%",
            content: "of customers who do not get option to split payments do not make medical treatment at all"
          }
        },
        start: {
          title: "Start here, and right now (it's totally free)",
          content: "Simple sign up, easy integration, tools to show your patients new ways to pay, healthy customer and his cash flow is win for you. Take it all today!"
        },
        solution: {
          title1: "We have solution for online and physical shops ",
          title2: "in one tool",
          content: "Getting up and running with Finamba is hassle-free. We’re staffed and skilled to quickly integrate with your clinic. With quick API integrations from our banking partners, you’ll be up and running with Finamba in no time."
        },
        footer: {
          title: "Ready to keep your patient happy, healthy and with balanced personal cash flow?",
          button: {
            label: "get started for free"
          }
        }
      },
      // partner page
      partner: {
        title1: "With one integration",
        title2: "get access to a large network of clinics",
        title: {
          content: "Finamba offer to expand your presence and enter into the “buy now, pay later” market fast and without any hustle.",
          button: {
            label: "CONTACT US",
          }
        },
        buy: {
          title: "Buy now, pay later figures",
          content: "According to Filene Research Institute total point-of-sale financing market is at $391 billion in US alone, equating to approximately 3.5% of annual consumer spending. The fever is spreading around the world.",
          item1: {
            title: "50%",
            subtitle: "Rejected customers",
            content: "The rejected customer amount varies in every merchant channel, starting from 30$ to even 70%. In average 50% of customers are rejected.",
          },
          item2: {
            title: "350 euro",
            subtitle: "Average ticket",
            content: "The average sales loan amount is 350 euros. The amounts usually start from 70 euros and can go high as 7.500 euros for bigger purchases.",
          },
          item3: {
            title: "15k",
            subtitle: "Customer amount",
            content: "In average 15.000 customer are using sales financing in Latvia a month.",
          },
          item4: {
            title: "14 months",
            subtitle: "Average term",
            content: "Usually, the sales loan term starts from 3 months and can go up to 60 months or even more. The average term is 14 months.",
          },
          item5: {
            title: "1-2%",
            subtitle: "Defaults",
            content: "Historically, sales financing has a very low default rates, starting from 1% to 2%.",
          },
          item6: {
            title: "50%",
            subtitle: "POS lending",
            content: "Starting from 2015, point of sale lending in US has grown more than 50% each year (150bUSD)",
          }
        },
        reason: {
          title: "Why choose Finamba?",
          item1: {
            title: "One integration",
            content: "With one integration you get access to a large network of clinics. We are adding new clinics at our network every week."
          },
          item2: {
            title: "Effortless",
            content: "To successfully enter the buy now and pay later market needs a lot of effort coordination, focus, innovations, developments, market knowledge, network, etc."
          },
          item3: {
            title: "Boost your portfolio",
            content: "Buy now, pay later product is a possibility to boost your loan portfolio and get new revenue streams."
          },
          item4: {
            title: "Your customers",
            content: "It makes so much sense that at point of sales I can choose my own core bank or lender instead of unknown fintech. Serve your own customers, do not allow drift them away to other companies."
          },
          item5: {
            title: "Trusted expertise",
            content: "Our team has many year experience in sales financing market. We understand how the market works, what is the flaws and problems. We develop the platform from ‘’first principle’’ approach."
          },
          item6: {
            title: "Reputation and transparency",
            content: "We believe that the reputation, honesty and transparency are the key factors for successful partnership with banks and merchants, as well by maintaining great customer relationship. These are the virtues we value the most."
          },
          item7: {
            title: "No need for sales team",
            content: "Usual buy now, pay later operations include a substantial sales team. By choosing Finamba you will not need any sales team, we will do all the talking for you. This saves you much needed resources on other things."
          },
        },
        benefit: {
          title: "Finamba platform benefits",
          item1: {
            title: "Omni-channel",
            content: "Nowadays, the online and physical channels are blending. For more merchants, the online is only a selling channel rather than a business model, they use both channels. We have developed solutions for both worlds – online and physical."
          },
          item2: {
            title: "Ease of integration",
            content: "We ensure that you are up and running in matter of weeks, not months. Our highly capable developer team will help you at every step on the journey. We will can help you with any integrations you need, just ask us."
          },
          item3: {
            title: "Technology and innovations",
            content: "The current solution is made with highly convenient process for the customer. Our daily life consists of technological and process improvements thinking about the customer, merchants and banks or lenders ease-of-use and pleasant interaction with platform."
          },
          item4: {
            title: "Online products",
            content: "Our platform allows you to add and erase products online without any restrictions. Add interest, 0 interest, 14 days for free, and other products as you wish."
          },
        },
        icons: {
          title: "Trusted by leading clinics"
        },
        footer: {
          title1: "Ready to start the journey into ",
          title2: "buy now, pay later world?",
          button: {
            label: "CONTACT US"
          }
        }
      },
      aboutUs: {
        title: "Let's build financial tools for people",
        ourMission: {
          title: "Our mission",
          content1:
            "Our mission is to increase every person's financial literacy and help them to make every day financial decisions by developing and giving tools for that.",
          content2:
            "Financial literacy is crucial for wealth, success, protection, and life balance. We all can be Finambas.",
        },
        ourValues: {
          title: "Our values",
          content:
            "At Finamba, all of our work is guided by our core values. These values provide a shared framework across the company for collaboration and growth as we strive to deliver on our mission to clarify all of life's financial decisions.",
        },
        card1: {
          title: "Honesty and Transparency",
          content:
            "The value king. Our daily life is fueled with honesty and transparency. Honesty is deep in our bones. If we can be trusted by our colleagues, our users, our partners, we are in deep trouble.",
        },
        card2: {
          title: "Money",
          content:
            "Money is our everyday life. We talk money, live money, walk money. We love to save money for our customers. We constantly are in search for a way to make money topics easy, understandable, transparent, and usable.",
        },
        card3: {
          title: "Boldness",
          content:
            "We are not afraid to admit our mistakes, we are not afraid to express our opinions, we are not afraid to move in an unknown territory. We make decisions, learn from them, and correct them if needed.",
        },
        card4: {
          title: "Balance, but HWPO",
          content:
            "Balance is a key to happy and successful life. We balance our work, life, nutritions, hobbies, sports, and give people opportunities to do it. However, we believe that hard work pays off. If we are at work, we kick it and grind it hard.",
        },
      },
      contactUs: {
        title: "Contact us",
        form: {
          title:
            "Please complete and submit the form below to help us answer your e-mail as completely and quickly as possible.",
          requiredField: "Required Field",
          footerText:
            "When you press the submit button, you will receive an acknownledgement that your e-mail was received. (Your personal information and privacy is protected. Please read our privacy policy for details)",
          submit: "Submit",
          emailAddress: "Email address",
          messageSubject: "Name",
          phoneNumber: "Phone number",
          message: "Message",
        },
        social: {
          title: "Connect with us",
          facebook: "Facebook",
          twitter: "Twitter",
          linkedIn: "LinkedIn",
        },
        feedback: {
          gotIt: "Got it!",
          getInTouch: "Thank you for getting in touch!",
          content: "We appreciate you contacting us. One of our team members will get back in touch with you soon!",
          footer: "Have a great day!",
        }
      },
    },
    footer: {
      phoneNumber: "+371 2377 7387",
      mailAddress: "info@finamba.com",
      about: {
        title: "About us",
        text1: "About Finamba",
        text2: "Contacts",
        text3: "FAQ",
        text4: "Careers",
        text5: "Advertise with us",
        text6: "Site map"
      },
      tools: {
        title: "Tools",
        text1: "Forum",
        text2: "Calculators",
        text3: "Finance Class",
        text4: "Insights",
        text5: "Savings",
        text6: "Investments"
      },
      legal: {
        title: "Legal",
        text1: "Privacy policy",
        text2: "Data protection/confidentiality",
        text3: "License"
      },
      how: {
        title: "How we make money",
        text: "Finamba is an independent finance product match-making service. Finamba is compensated for each successful transaction by loan provider within Finamba network. We will never take money from our users. We are here to provide value for our customers."
      },
      copyright: "© 2021 Finamba, All Rights Reserved."
    },
    aspatient: {
      text: {
        title: "Sollicitudin faucibus ac tellus nulla",
        label1: "Procedure price",
        label2: "Indicate your clinic, if you want to receive special offers",
        button: {
          label: "APPLY NOW"
        }
      }
    }
  },
};
